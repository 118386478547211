import {Partner} from './partner';

export class Organization {
  orgId: number;
  orgCode: string;
  orgDomain: string;
  orgName: string;
  surveyRequired: boolean;
  partner: Partner;
}
